import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  ConceptMedia,
} from '../../../components/_index';
import '../../../assets/_sass/page/privacy.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="small"
        data={{
          title: {
            main: 'wedding party TERMS OF USE',
            sub: 'ご結婚披露宴規約',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/privacy/site/kv.png',
              },
              imgSp: {
                src: '/assets/images/privacy/site/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '規約・約款',
                path: '/privacy/',
              },
            ],
            current: {
              label: 'ご結婚披露宴規約',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect p_privacy">
        <LWrap exClass="u_mbExLarge">
          <h2 className="c_headingLv2">ご結婚披露宴規約</h2>
          <p className="u_mb25">
            この度は、ご両家の晴れのご婚儀のご用命を賜り誠にありがとうございます。
            <br />
            当ホテルではご結婚披露宴のご契約および宴会場のご利用について、以下の通り定めております。必ずご一読の上、予めご了承くださいますようお願い申し上げます。
          </p>
          <h3 className="c_headingLv5">1. ご契約の成立</h3>
          <p className="u_mb25">
            お申込金20万円のご入金と当ホテルが定めた「ご結婚式・ご披露宴契約書」に必要事項をご記入いただき、その内容をホテルが確認のうえ、応諾の意思表示をした時をもってご契約とさせていただきます。お申込金の取扱いは下記「2.お支払い」に定めております。
          </p>
          <h3 className="c_headingLv5">2. お支払い</h3>
          <p className="u_mb25">
            前記「1.ご契約の成立」にてご入金いただいたお申込金20万円の他、最終打合せ終了後にお客様が了承された見積金額を、ご披露宴当日の5営業日前までに現金、クレジットカードまたはお振込みにてお支払いください。当該見積金額を期限までにお支払いいただけない場合、ご契約を解約させていただくことがございます。ご披露宴終了後に確定するご請求金額に対し、ご披露宴当日の5営業日前までにお支払いいただいた金額とお申込金を合わせた金額の過不足について、ご披露宴終了後に精算させていただきます。なお、本契約に基づくご請求は全てご新郎ご新婦双方にて連帯してお支払いをいただきます。
          </p>
          <h3 className="c_headingLv5">3. ご披露宴時間と追加室料</h3>
          <p className="u_mb25">
            ご披露宴等の会場の開場から会場の閉場まで（以下、「会場使用時間」と言います。）のご宴会時間は所定の室料をお支払いいただきます。
            <br />
            会場使用時間を超過した場合は、それに応じて所定の追加料金を頂戴いたします。但し、次の会場使用時刻との関連でご使用時間の超過に応じられない場合もございますので予めご了承ください。
          </p>
          <h3 className="c_headingLv5">4. お打合せ</h3>
          <p className="u_mb25">
            ご披露宴等の内容については、事前にお打合せください。また、最終のお打合せは、ご披露宴等開催日の2週間前までに当ホテルの担当者とお済ませください。
          </p>
          <h3 className="c_headingLv5">5. ご出席人数の変更</h3>
          <p className="u_mb25">
            お料理等をご用意する人数のご変更はご披露宴当日の3営業日前の17時までに当ホテルの担当者にお知らせください。それ以降に人数が減少した場合は「6.ご披露宴のキャンセル料」に記載のキャンセル料を頂戴いたします。
          </p>
          <h3 className="c_headingLv5">6. ご披露宴のキャンセル料</h3>
          <p className="u_mb25">
            すでにご契約いただきましたご披露宴のキャンセルまたは日程を変更される場合は下記のキャンセル料を頂戴いたします。また、後記「9.ご契約締結の拒否・解約」の場合も同様といたします。なお、ご新郎ご新婦いずれか一方より意思表示があった日を下記「期日」といたします。
          </p>
          <table className="c_fixedTable c_fixedTable--wedding">
            <tbody>
              <tr>
                <th className="thTitle">キャンセル日</th>
                <td className="tdTitle">キャンセル料金</td>
              </tr>
              <tr>
                <th>
                  1. 契約日の5日後から
                  <br className="u_sp" />
                  披露宴当日の365日前まで
                </th>
                <td>
                  手配済みのものの実費（再利用可能なものを除く）及びそれに付随するサービス料相当額。
                  <br />
                  但し、お申込金額を上限といたします。
                </td>
              </tr>
              <tr>
                <th>
                  2. 披露宴当日の
                  <br className="u_sp" />
                  364日前から180日前まで
                </th>
                <td>
                  室料の40%、その他手配済みのものの実費（再利用可能なものを除く）
                  <br />
                  及びそれに付随するサービス料相当額。
                </td>
              </tr>
              <tr>
                <th>
                  3. 披露宴当日の
                  <br className="u_sp" />
                  179日前から90日前まで
                </th>
                <td>
                  室料の50%、その他手配済みのものの実費（再利用可能なものを除く）
                  <br />
                  及びそれに付随するサービス料相当額。
                </td>
              </tr>
              <tr>
                <th>
                  4. 披露宴当日の
                  <br className="u_sp" />
                  89日前から30日前まで
                </th>
                <td>
                  室料の65%、その他手配済みのものの実費（再利用可能なものを除く）
                  <br />
                  及びそれに付随するサービス料相当額。
                </td>
              </tr>
              <tr>
                <th>
                  5. 披露宴当日の
                  <br className="u_sp" />
                  29日前から15日前まで
                </th>
                <td>
                  室料の65%、その他手配済みのものの実費（再利用可能なものを除く）
                  <br />
                  及びそれに付随するサービス料相当額。
                </td>
              </tr>
              <tr>
                <th>
                  6. 披露宴当日の
                  <br className="u_sp" />
                  14日前から披露宴当日まで
                </th>
                <td>
                  室料の70%、その他手配済みのものの実費（再利用可能なものを除く）
                  <br />
                  及びそれに付随するサービス料相当額。
                </td>
              </tr>
            </tbody>
          </table>
          <h3 className="c_headingLv5">7. 装飾・余興等の手配</h3>
          <p className="u_mb25">
            ご披露宴に関する装飾・音楽・余興等につきましては、当ホテルより当ホテルの契約会社へ手配させていただきます。余興の際、高音を発する楽器・機器(エレキギター・トランペット・フルバンド・太鼓等)の使用は防音の関係上お断りいたします。当ホテルの了解のもとにお客様が当ホテルの契約会社以外に直接依頼される場合は、装飾、余興等の機器および材料の搬入・搬出、または看板等のサイズ、取り付け方法、場所等について、当ホテルの美観・導線等を考慮し、当ホテルの指示に従い行っていただくよう手配会社の方々に案内させていただきます。尚、当ホテル係員の立会いが必要な場合、別途立会い人件費を頂戴いたします。{' '}
          </p>
          <h3 className="c_headingLv5">8. 施設内の事故・盗難等</h3>
          <p className="u_mb25">
            お客様（お客様側の全ての関係者を含みます）およびお客様が直接依頼された手配会社の方々におかれましては、当ホテルの施設・什器備品等を破損・損傷しないようご注意ください。
            <br />
            損傷等の損害が発生しました場合は、その修復に関して当ホテルよりご指示申し上げますのでそれに合わせて速やかに修理をしていただくか、損害賠償金をご負担いただきます。尚、お客様の管理下で発生した事故・盗難につきましては、当ホテルの故意または重大な過失がある場合を除き、一切責任を負いませんので十分ご注意ください。
          </p>
          <h3 className="c_headingLv5">9. ご契約締結の拒否・解約</h3>
          <p className="u_mb25">
            次に掲げる場合においては、ご契約を拒否または解除することがあります。
            <br />
            (1)ご披露宴に出席されるお客様が、ご結婚披露宴規約（＝本規約）、法令の規定、公の秩序若しくは善良の風俗に反する行為をするおそれがあると認められるとき、または同行為をしたと認められるとき。
            <br />
            (2)ご披露宴に出席されるお客様が、他のお客様に迷惑を及ぼす行為をしたときまたは迷惑を及ぼす恐れがある場合。
            <br />
            (3)ご披露宴に出席されるお客様が、当ホテルまたは当ホテル従業員に対し、不当要求行為を行い、または合理的な範囲を超える負担を要求したとき。
            <br />
            (4)お申込みもしくはお打合せ等の際に、虚偽の事実を申告し、もしくは重要な事実を申告しなかった場合、その他宴会場のご利用が不適当であると当ホテル側が判断した場合。
            <br />
            次に掲げる場合においては、ご契約を拒否または解除いたします。
            <br />
            (1)暴力団員、または暴力団等の暴力関係団体その他反社会的勢力の関係者。
            <br />
            (2)暴力団または暴力団員が事業活動を支配する法人その他の団体の関係者。
          </p>
          <h3 className="c_headingLv5">10. 禁止事項</h3>
          <p className="u_mb25">
            当ホテルの施設利用規則のご遵守をお願いいたします。この規則を遵守いただけない場合は、当ホテルの利用をお断り申し上げます。次に掲げる事項についても、禁止とさせていただきます。
            <br />
            (1)肖像権等第三者の権利を侵害する映像や画像および音楽著作権に関する適正な手続きをせずに作成された映像のお持込み、使用または上映。
            <br />
            (2)合理的な理由なく当ホテルからの連絡に対する14日以上の音信不通。
            <br />
            (3)見積額や当ホテルとの契約内容について、当ホテルの同意なく対外的に開示すること。
          </p>
          <h3 className="c_headingLv5">11. その他</h3>
          <p className="u_mb25">
            (1)本規約は予告することなく変更することがあります。
            <br />
            (2)当ホテルは施設および景観の保全・維持管理などに伴い建物・植栽・館内の装飾品・器具・備品類等を予告なく変更する場合がございます。また、周辺環境や眺望が変更される場合があることを予めご了承ください。
            <br />
            (3)個人情報保護の観点から、参列者等（ご新郎・ご新婦のご両親含む）の個人情報を当ホテルに提供いただく場合、口頭で結構ですので参列者等（情報主体者）に事前に了承を得ていただきますようお願い申し上げます。プライバシーポリシーは当ホテルホームページよりご確認いただけます。
            <br />
            (4)当ホテルは所定の料金以外、お心付けなど一切ご辞退申し上げております。
          </p>
          <p className="u_tar">(制定)2023年1月1日</p>
        </LWrap>
      </section>
      {/* <ConceptMedia /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
